.badge {
    text-transform: $badge-text-transfom;

    a {
        color: #FFF;
    }
}

// Variations
.badge-pill {
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
    &.big{
        padding: 16px;
        border: 1px solid #E8E8E8;
        color:#E8E8E8;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(17, 17, 17, 0.02);
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.2);
        margin:8px;
    }
}



.badge-circle {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    font-size: .875rem;
    &.small{
        width: 16px;
        height: 16px;
        font-size: 12px;
        &.gradient{
            background: linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
        }
    }
}

// Multiple inline badges
.badge-inline {
    margin-right: .625rem;
}

.badge-inline + span {
    top: 2px;
    position: relative;
}

.badge-inline + span > a {
    text-decoration: underline;
} 

// Sizes
.badge-md {
    padding: .65em 1em;
}

.badge-lg {
    padding: .85em 1.375em;
}


// Color variations

.badge-secondary {
    color: $gray-800;
}

.badge-custom {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 2px;
    color:$white;
    padding: 8px 16px;
    margin-top:24px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-transform: none;
    white-space: break-spaces;
}

// Link badges

.btn {
    .badge {
        &:not(:first-child) {
            margin-left: .5rem;
        }
        &:not(:last-child) {
            margin-right: .5rem;
        }
    }
}