// General

.btn {
    position: relative;
    text-transform: $btn-text-transform;
    transition: $transition-base;
    will-change: transform;
    letter-spacing: $btn-letter-spacing;
    font-size: $input-btn-font-size;
    
    &:hover {
        @include box-shadow($btn-hover-box-shadow);
        transform: translateY(0px);
    }  

    &:not(:last-child) {
        margin-right: .5rem;
    }
}

.btn-group,
.input-group {
    .btn {
        margin-right: 0;
        transform: translateY(0);
    }
}

// Sizes

.btn-sm {
    font-size: $input-btn-font-size-sm;
}

.btn-lg {
    font-size: $input-btn-font-size-lg;
}

// Fixes

[class*="btn-outline-"] {
    border-width: 1px;
}
.btn-outline-secondary {
    color: darken(theme-color("secondary"), 50%);
}

.btn-inner--icon {
    i:not(.fa) {
        position: relative;
        top: 2px;
    }
}



.btn-link {
    font-weight: $btn-font-weight;
    box-shadow: none;
    &.customLink{
        color:$custom!important;
        box-shadow: none;
        padding: 0;
        position: relative;
        text-transform: none;
        &::after{
            content:"";
            margin-left: 8px;
            width: 24px;
            height: 24px;
            display: block;
            float: right;
            background-image: url('../../img/brand/Arrow-secondary.svg');
        }
    }

    &:hover {
        box-shadow: none;
        transform: none;
    }
}

.btn-neutral {
    color: theme-color("primary");
}

// Icons

.btn svg:not(:first-child),
.btn i:not(:first-child) {
    margin-left: 0.5rem;
}
 
.btn svg:not(:last-child),
.btn i:not(:last-child) {
    margin-right: 0.5rem;
}

// Icon labels

.btn-icon-label {
    position: relative;

    .btn-inner--icon {
        position: absolute;
        height: 100%;
        line-height: 1;
        border-radius: 0;
        text-align: center;
        margin: 0;
        width: 3em;
        background-color: rgba(0, 0, 0, .1);
    }
    .btn-inner--icon:not(:first-child) {
        right: 0;
        top: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }
    .btn-inner--icon:not(:last-child) {
        left: 0;
        top: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
    }
    .btn-inner--icon svg {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .btn-inner--text:not(:first-child) {
        padding-left: 3em;
    }
    .btn-inner--text:not(:last-child) {
        padding-right: 3em;
    }
}


// Icons 

.btn-icon {
    .btn-inner--icon {
        img {
            width: 20px;
        }
    }
    .btn-inner--text:not(:first-child) {
        margin-left: .75em;
    }
    .btn-inner--text:not(:last-child) {
        margin-right: .75em;
    }
}

.btn-icon-only {
    width: 2.375rem;
    height: 2.375rem;
    padding: 0;
}
a.btn-icon-only {
    line-height: 2.5;
}
.btn-icon-only.btn-sm {
    width: 2rem;
    height: 2rem;
}


// Brand buttons

@each $color,
$value in $brand-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

.btn-custom{
    font-size: 16px;
    text-transform: none;
    font-weight: normal;
    box-shadow:none;
    &::after{
        content:"";
        margin-left: 8px;
        width: 24px;
        height: 24px;
        display: block;
        float: right;
        background-image: url('../../img/brand/Arrow.svg');
    }
    &.secondary{
        background-color: #FFF!important;
        color:$custom;
        border: none;
        span{
            background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
            font-size: 18px;
        }
        &::after{
            content:"";
            background-image: url('../../img/brand/Arrow-secondary.svg');
        }

    }

    &.flat{
        background-color: transparent!important;
        color:#3E72F9;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        padding: 0px;
        border: none;

        &::after{
            content:"";
            background-image: url('../../img/brand/arrow-blue.png');
            margin-top: 2px;
            background-repeat: no-repeat;
            background-position-y: 5px;
        }

        &.back{
            &::after{
                content:"";
                background-image: url('../../img/brand/arrow-blue.png');
                margin-top: 2px;
                background-repeat: no-repeat;
                background-position-y: 5px;
                transform: rotate(180deg);
                margin-left: 0px;
            }
        }

        &:hover, &:focus{
            box-shadow:none;
        }

    }


    &.noarrow{
        &::after{
            display: none;
        }
    }
    &:disabled{
       background-color: #8e8e9b!important;
       border-color: #8e8e9b!important;
       &:hover{
        box-shadow: none;
        transform: translateY(0px);
       }
    }
  }