// General styles

p {
  font-size: $paragraph-font-size;
  font-weight: $paragraph-font-weight;
  line-height: $paragraph-line-height;
}

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
  line-height: $paragraph-line-height;
  margin-top: 1.5rem;

  + .btn-wrapper {
    margin-top: 3rem;
  }
}

.description {
  font-size: $font-size-sm;
}

// Headings

.heading {
  letter-spacing: $heading-letter-spacing;
  font-size: $heading-font-size;
  text-transform: $heading-text-transform;
  font-weight: $heading-font-weight;
}

.heading-title {
  letter-spacing: $heading-title-letter-spacing;
  font-size: $heading-title-font-size;
  font-weight: $heading-title-font-weight;
  text-transform: $heading-title-text-transform;
}

.heading-section {
  letter-spacing: $heading-section-letter-spacing;
  font-size: $heading-section-font-size;
  font-weight: $heading-section-font-weight;
  text-transform: $heading-section-text-transform;

  img {
    display: block;
    width: 72px;
    height: 72px;
    margin-bottom: 1.5rem;
  }

  &.text-center {
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// Section titles

.display-1,
.display-2,
.display-3,
.display-4 {
  span {
    display: block;
    font-weight: $font-weight-light;
  }
}

.main-title {
  font-style: normal;
  font-weight: 800;
  font-size: 46px;
  span {
    display: block;
    font-weight: $font-weight-light;
  }
}

.home-title{
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  color: #333333;
}

.gradient-title{
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 160%;
  text-align: center;
  background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.contact-us-title{
  font-weight: 800;
  font-size: 56px;
  line-height: 140%;
  text-align: center;
  color: #4E4E4E;

  margin-bottom:30px
}

.contact-us-sub-title{
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 160%;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-top:40px
}

.whatapp{
  position: relative;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 160%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 80px;
  span{
    margin-left: 20px;
  }
}

.title-2 {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $black-text;
  span {
    display: block;
    font-weight: $font-weight-light;
  }
}
.title-3 {
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  color: #0a0a0b;
}

.title-32 {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0a0a0b;
}

.big-title {
  font-size: 3rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff;
}

.sub-big-title {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
}

.sub-title {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #8e8e9b;
}

.text-15 {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #000000;
}

.form-title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: normal;
  color: #0a0a0b;
}

.form-title-2 {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  text-align: center;

  margin-bottom: 8px;
  b{
    background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.bullet-form {
    background-image: url("../../img/brand/si-purple.svg");
    background-repeat: no-repeat;
    padding-left: 30px;
    background-size: 24px;
    background-position-y: 0;
    b{
      background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

}

.text-form{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #9C9C9C;
}

.form-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color:#9C9C9C;
}

.form-stepper {
  padding: 24px 0;
  li {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #9C9C9C;
    list-style: none;
    width: 100%;
    display: block;
    float: left;
    padding-bottom: 16px;
    &.past {
      color: #9C9C9C;
      span {
        border: 1px solid #9C9C9C;
      }
    }
    &.active {
      //color: #ed8200;
      background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      span {
        border: 1px solid  #9E3BEC
        //border:1px solid red
      }
    }
    span {
      border: 1px solid #9C9C9C;
      border-radius: 100%;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      display: block;
      text-align: center;
      float: left;
    }
  }
}

.msg-form {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #67676b;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #fafafa;
}

.small-text{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #9C9C9C;
}

// Articles

article {
  h4:not(:first-child),
  h5:not(:first-child) {
    margin-top: 3rem;
  }

  h4,
  h5 {
    margin-bottom: 1.5rem;
  }

  figure {
    margin: 3rem 0;
  }

  h5 + figure {
    margin-top: 0;
  }
}

.list-bullet {
  padding: 0;
  list-style: none;
  li {
    position: relative;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #0a0a0b;
    b {
      font-weight: 600;
    }
    &:before {
      content: "";
      background-image: url("../../img/brand/check.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      display: block;
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}


.list-bullet-in-card {
  padding: 0;
  list-style: none;
  li {
    text-transform: none;
    position: relative;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #0a0a0b;
    b {
      font-weight: 600;
    }
    &:before {
      content: "";
      background-image: url("../../img/icons/mdi_check.svg");
      background-size: cover;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      display: block;
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.card-radio {
  width: 20px;
  height: 20px;
  border: solid 1px #0a0a0b;
  background-color: #ffffff;
}

.card-radio .selected {
  width: 10px;
  height: 10px;
  box-shadow: inset 0 0 3.3px 0 rgba(0, 0, 0, 0.25);
  background-color: #212a6e;
}
