.custom-control-label {
    // Background-color and (when enabled) gradient
    &::before {
        border: $custom-control-indicator-border-width solid #333;
        @if $enable-transitions {
            transition: $input-transition;
        } 
    }

    span {
        position: relative;
        top: 2px;
        a{
            font-weight: bold;
            &:hover{
                color:#ed8200!important;
            }
        }
    }
} 

.custom-control-label {
    margin-bottom: 0;
}

.custom-control-input {
    &:active~.custom-control-label::before {
        border-color: $custom-control-indicator-active-border-color;
    }
}

// Alternative custom control 
.custom-control-alternative {
    .custom-control-label {
        // Background-color and (when enabled) gradient
        &::before {
            border: 0;
            box-shadow: $input-alternative-box-shadow;
        }
    } 

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    box-shadow: $input-focus-alternative-box-shadow;
                }
            }
        }

        &:active~.custom-control-label::before,
        &:focus~.custom-control-label::before {
            box-shadow: $input-alternative-box-shadow;
        }
    }
}


// Checkboxes
.custom-checkbox {
    .custom-control-input~.custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    &.white{
        border-radius: 4px;
        border: solid 1px #e0e0e0;
        background-color: #ffffff;
        margin-bottom: 8px;
        padding: 0;
        label{
            margin-left: 28px;
            width: 100%;
            padding: 10px 12px 14px 12px;
            &:after{
                left: -16px;
                top:12px;
            }
            &:before{
                left: -16px;
                top:12px;
            }

        }
    }

    .custom-control-input {

        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }
                &::after {
                    background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}



// Radios
.custom-radio {
    .custom-control-input~.custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    &.box{
        background-color: #FFF;
        border: 1px solid #9C9C9C;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 16px;
        .custom-control-label{
          margin-left:1.75rem;
          p{
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            margin:0;
            color:#0A0A0B;
          }
        }
      }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                    background-color: white;
                }
                &::after { 
                    width: 12px;
                    height: 12px;
                    background-color: $custom-control-indicator-checked-border-color;
                    border-radius:100%;
                    background-image:  none;
                    margin: 4px;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }

}

// Toggles
.custom-toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 1.5rem;

    input {
        display: none;

        &:checked {
             + .custom-toggle-slider {
                border: $custom-control-indicator-border-width solid $custom-control-indicator-checked-border-color;

                &:before {
                    background: $custom-toggle-checked-bg;
                    transform: translateX(1.625rem);
                }  
            }
        }
        
        &:disabled {
            + .custom-toggle-slider {
                border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;
            }

            &:checked {
                + .custom-toggle-slider {
                    border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;

                    &:before {
                        background-color: lighten($custom-control-indicator-checked-bg, 10%);
                    }
                }
            }
        }
    }
}

.custom-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: $custom-control-indicator-border-width solid $input-border-color;
    border-radius: 34px !important;
    background-color: transparent;
    

    &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 2px;
        bottom: 2px;
        border-radius: 50% !important;
        background-color: $custom-toggle-slider-bg;
        transition: $input-transition;
    }
}
