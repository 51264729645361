@each $color, $value in $colors {
	@include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $theme-colors {
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}

@each $color, $value in $colors { 
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}


// Sections

.bg-blue-custom{
    background-color: #212a6e!important;
    border-radius: 0 !important;
    &.bg-shadow{
      box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.32);
    }
  }

  .bg-gradient{
    background: linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
  }

  
  .bg-dark-grey{
    background-color: #f7f7f7;
  }

  .bg-light-grey{
    background-color: #fcfcfc;
    &.pattern-right{
      background-image: url('../../../img/brand/Pattern.png');
      background-position: bottom right;
      background-repeat: no-repeat;
    }
  }



  .fill-light-grey{
    fill: #fcfcfc;
  }

  .fill-white{
    fill: #FFF;
  }

  


@each $color, $value in $section-colors {
    @include bg-variant(".section-#{$color}", $value);
}

@each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}


// Shape (svg) fill colors
@each $color, $value in $theme-colors {
    .fill-#{$color}  {
        fill: $value;
    }

    .stroke-#{$color}  {
        stroke: $value;
    }
}

.fill-opacity-8 {
	fill-opacity: .8;
}