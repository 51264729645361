.progress-wrapper {
    position: relative;
    padding-top:0;
    p{
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: normal;
        color: #8e8e9b;
        padding-left: 16px;
        display: flex;
        align-items: center;
        align-content: center;
    }
}
.progress {
    height: 8px;
    margin-bottom: 16px;
    overflow: hidden;
    border-radius:0;
    background-color: #E8E8E8;
    margin-top: -8px;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.progress .sr-only {
    width: auto;
    height: 20px;
    margin: 0 0 0 30px;
    left: 0;
    clip: auto;
    line-height: 20px;
    font-size: 13px;
}

.progress-heading {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 2px;
    padding: 0;
}
.progress-bar {
    box-shadow: none;
    border-radius: 0;
    height: auto;
    border-radius: 0 80px 80px 0;
    //background-color: #2f2c99;
    background: linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
}

.progress-info{
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.progress-label {
    span {
        display: inline-block;
        color: $primary;
        font-size: .625rem;
        font-weight: 600;
        text-transform: uppercase;
        background: rgba($primary, .1);
        padding: .25rem 1rem;
        border-radius: 30px;
    }
}

.progress-percentage {
    text-align: right;
    span {
        display: inline-block;
        color: $gray-600;
        font-size: .875rem;
        font-weight: 600;
    }
}
