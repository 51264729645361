.footer {

    background: #FAFAFA;
    color: #333333;
    padding: 1rem 0;
    position:relative;
    //color: $footer-color;
    &.separator-top{
        top:-160px!important;
    }

    p.title-menu{
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.8);
        text-transform: uppercase;
    }
    p.legal{
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        padding-top: 24px;
    }


    .col-footer {
        .heading {
            color: $footer-heading-color;
            letter-spacing: 0;
            font-size: $footer-heading-font-size;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-bottom: 1rem;
        }
    }

    .nav .nav-item .nav-link,
    .footer-link {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        padding: 8px;
        text-transform: uppercase;
        color:#4E4E4E;
        &.user{
            padding: 4px;
            text-transform: none;
            font-weight: 600;
            font-size: 18px;
            b{
            background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
            &::before{
                content:"";
                margin-right: 8px;
                width: 32px;
                height: 30px;
                display: block;
                float: left;
                background-image: url('../../img/brand/Loguito.svg');
            }
        }
    }

    .list-unstyled li a {
        display: inline-block;
        padding: .125rem 0;
        color: $footer-link-color;
        font-size: $footer-link-font-size;

        &:hover {
            color: $footer-link-hover-color;
        }
    }

    .address{
        color: #9C9C9C;
        font-size: 16px;
        line-height: 26px;
    }

    .tel{
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        b{
            font-size: 20px; 
        }
    }

    .legacy {
        font-weight: normal;
        font-size: 12px;
        color: #9C9C9C;
    }

    .copyright{ 
        font-weight: 600;
        font-size: 14px;
        color: #4E4E4E;
    }
}

.footer-dark {
    .col-footer .heading {
        color: $white;
    }
}

.footer.has-cards {
    overflow: hidden;
    padding-top: 500px;
    margin-top: -420px;
    position: relative;
    background: transparent;
    pointer-events: none;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 600px;
        height: 2000px;
        background: theme-color("secondary");
        transform: skew(0,-8deg);
    }

    .container {
        pointer-events: auto;
        position: relative;
    }
}

.nav-footer {
    .nav-link {
        font-size: $font-size-sm;
    }

    .nav-item:last-child {
        .nav-link {
            padding-right: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer{
        .user-logo{
            display: flex;
            flex-direction: column;
            &:before{
                width: 50px;
                height: 45px;
                margin:auto;
                background-size: cover;
            }
        }
    }
}
