// Nucleo icons

.section-nucleo-icons .icons-container {
    position: relative;
    max-width: 100%;
    height: 360px;
    margin: 0 auto;
    z-index: 1;
}

.section-nucleo-icons {

    --icon-size: 5rem;
    --icon-sm-size: 3.75rem;
    --gutter: 7rem;

    .icons-container {
        i {
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: $white;
            z-index: 1;
            transform: translate(-50%, -50%);
            @include box-shadow($box-shadow);
            transition: all .2s cubic-bezier(.25,.65,.9,.75);

            &.icon {
                width: var(--icon-size);
                height: var(--icon-size);
                font-size: 1.7em;
            }

            &.icon-sm {
                width: var(--icon-sm-size);
                height: var(--icon-sm-size);
                font-size: 1.5em;
            }

            &:nth-child(1) {
                font-size: 42px;
                color: theme-color("warning");
                z-index: 2;
            }
        }

        &:not(.on-screen) {
            i {
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;

                &:not(:nth-child(1)) {
                    opacity: 0;
                } 
            }
        }

        &.on-screen {

            i {
                opacity: 1;

                &:nth-child(1) {
                    left: 50%;
                    top: 50%;
                    font-size: 42px;
                    color: theme-color("warning");
                }

                &:nth-child(2) {
                    left: calc(50% + (var(--gutter) * 1.7));
                    top: 50%;
                }

                &:nth-child(3) {
                    left: calc(50% + var(--gutter));
                    top: calc(50% + var(--gutter));
                }

                &:nth-child(4) {
                    left: calc(50% + var(--gutter));
                    top: calc(50% - var(--gutter));
                }

                &:nth-child(5) {
                    left: calc(50% + (var(--gutter) * 4));
                    top: 50%;
                }

                &:nth-child(6) {
                    left: calc(50% + (var(--gutter) * 2.7));
                    top: calc(50% + (var(--gutter) * 1.5));
                }

                &:nth-child(7) {
                    left: calc(50% + (var(--gutter) * 2.7));
                    top: calc(50% - (var(--gutter) * 1.5));
                }

                &:nth-child(8) {
                    left: calc(50% - (var(--gutter) * 1.7));
                    top: 50%;
                }

                &:nth-child(9) {
                    left: calc(50% - var(--gutter));
                    top: calc(50% + var(--gutter));
                }

                &:nth-child(10) {
                    left: calc(50% - var(--gutter));
                    top: calc(50% - var(--gutter));
                }

                &:nth-child(11) {
                    left: calc(50% - (var(--gutter) * 4));
                    top: 50%;
                }

                &:nth-child(12) {
                    left: calc(50% - (var(--gutter) * 2.7));
                    top: calc(50% + (var(--gutter) * 1.5));
                }

                &:nth-child(13) {
                    left: calc(50% - (var(--gutter) * 2.7));
                    top: calc(50% - (var(--gutter) * 1.5));
                } 
            }
            
        }
    }
}


// Blurable sections

.blur--hover {
    position: relative;

    .blur-item {
        transition: 1s cubic-bezier(.19,1,.22,1);
        will-change: transform;
        filter: blur(0);
        opacity: 1;
    }

    .blur-hidden {
        position: absolute;
        top: calc(50% + 7px);
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: $transition-base;
        z-index: 100;
    }
    &:hover {
        .blur-item {
            opacity: .8;
            filter: blur(10px);
            transform: scale(.95);
            z-index: 1;
        }
        .blur-hidden {
            opacity: 1;
            top: 50%;
        }
    }
}



.user-logo{
    padding: 4px;
    text-transform: none;
    font-weight: 600;
    font-size: 18px;
    b{
    background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
    &::before{
        content:"";
        margin-right: 8px;
        width: 32px;
        height: 30px;
        display: block;
        float: left;
        background-image: url('../../img/brand/Loguito.svg');
    }
}

.shadow-svg {
    -webkit-filter: drop-shadow( 0px -8px 24px rgba(51, 51, 51, 0.07));
    filter: drop-shadow( 0px -8px 24px rgba(51, 51, 51, 0.07));
    /* Similar syntax to box-shadow */
  }

  .swiper-container.slider{
    padding: 20px 30px;
    margin: 0 -80px;
    .swiper-slide{
        width: 25%;
        min-width: 250px;
    }
}

@include media-breakpoint-down(sm) {
    .swiper-container.slider{
        padding: 20px 0px;
        margin: -170px -40px 0;
        .swiper-slide{
            width: 70%;
        }
    }
}

p{
    b{
        font-weight: bold;
    }
}

.text-black { color: #4E4E4E !important; }

.text-gray { color: #9C9C9C !important}

.max-720{
    max-width: 720px;
    margin:auto;
}

.max-420{
    max-width: 420px;
    margin:auto;
}