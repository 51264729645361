/* Form controls */

label {
    font-size: $font-size-sm;
}

.form-group {
    margin-bottom: 24px;
}

.form-control {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #0a0a0b;
    border-radius: 4px;
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    
    &:focus {
        border: solid 1px #0a0a0b;
        color: #0a0a0b;
        &::placeholder {
            color: $input-focus-placeholder-color;
        }
    }
    &.white{
        border: solid 1px #ffffff;
        background-color: rgba(255, 255, 255, 0.29);
        color: #FFF!important;
    }
    
    &.contact-input{
        border: 1px solid #9C9C9C;
        box-sizing: border-box;
        border-radius: 4px;
    }
}

.msg-input{
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    color: #0a0a0b;
    margin-top: -24px;
}

.has-danger{
    
    .form-control{
        border: solid 2px #e40404;
        &:focus {
            border-color: #e40404!important;
        }
    }
    &:after{
        content: ""!important;
        color:#e40404!important;
        background-color:transparent!important;
        background-image: url('../../img/icons/exclamation.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    & + .text-warning{
        margin-top: -24px;;
        margin-bottom: 24px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.33;
        letter-spacing: normal;
        color: #e40404!important;
    }
} 

.labelRoot{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #0a0a0b;
    padding-bottom: 8px;
} 


/* Textareas */

textarea[resize="none"] {
    resize: none!important;
}

textarea[resize="both"] {
    resize: both!important;
}

textarea[resize="vertical"] {
    resize: vertical!important;
}

textarea[resize="horizontal"] {
    resize: horizontal!important;
}


/* Alternative styles */

.form-control-muted {
    background-color: $input-muted-bg;
    border-color: $input-muted-bg;
    box-shadow: none;

    &:focus {
        background-color: $input-muted-bg;
        //box-shadow: none;
    }
}

.form-control-alternative {
    box-shadow: $input-alternative-box-shadow;
    border: 0;
    transition: box-shadow .15s ease;

    &:focus {
        box-shadow: $input-focus-alternative-box-shadow;
    }
}

// Sizes 

.form-control-lg {
    font-size: $font-size-base;
}

// Validation

.has-success,
.has-danger {
    position: relative;
    &:after {
        width: 19px;
        height: 19px;
        line-height: 19px;
        text-align: center;
        font-family: 'NucleoIcons';
        display: inline-block;
        position: absolute;
        right: 15px;
        top: 2px;
        transform: translateY(50%);
        border-radius: 50%;
        font-size: 9px;
        opacity: 1;
    }
}

.has-success {
    &:after {
        content: "\ea26";
        color: daken($form-feedback-valid-color, 18%);
        background-color: $form-feedback-valid-bg;
    }
    .form-control {
        background-color: $input-focus-bg;

        &:focus {
            border-color: $input-focus-border-color;
        }

        // Placeholder
        &::placeholder {
            color: $form-feedback-valid-color;
        }
    }

    &.focused {
        &:after {
            //opacity: 0;
        }
    }
}

.has-danger {
    &:after {
        content: "\ea53";
        color: daken($form-feedback-invalid-color, 18%);
        background-color: $form-feedback-invalid-bg;
    }
    .form-control {
        background-color: $input-focus-bg;

        &:focus {
            border-color: $input-focus-border-color;
        }

        // Placeholder
        &::placeholder {
            color: $form-feedback-invalid-color;
        }
    }

    &.focused {
        &:after {
            //opacity: 0;
        }
    }
}
