.card {
    position: relative;
    &.steps-home{
        border:0;
        .card-header{
            text-align: center;
            background-color: transparent;
            border: 0;
            padding: 0;
            width: 100%;
            margin: 0;
        }
        .card-title{  
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            color: #0a0a0b;
            margin-bottom: 8px;
        }
        .card-subtitle{
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: #67676b;
        }   
    }

    &.custom-home{
        background: #FFFFFF;
        box-shadow: 0px 2px 24px rgba(0, 17, 16, 0.16);
        border-radius: 4px;
        margin: 8px;
        min-height: 385px;
        .image{
            text-align: center;
            display: flex;
            height: 160px;
            img{
                margin:auto;
            }
        }
        .card-body {
            padding: 16px;
        }
        .card-title{
            font-style: normal;
            font-weight: 800;
            font-size: 22px;
            background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 8px;
        }
        .card-subtitle{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color:#4E4E4E;
        }
    }

    &.custom-about{
        background: #FFFFFF;
        box-shadow: 0px 2px 24px rgba(0, 17, 16, 0.16);
        border-radius: 4px;
        margin: 8px;
        min-height: 260px;
        width:230px;
        .image{
            text-align: center;
            display: flex;
            height: 160px;
            img{
                margin:auto;
            }
        }
        .card-body {
            padding: 16px;
        }
        .card-title{
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            text-align: center;
            background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 8px;
        }
        .card-subtitle{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color:#4E4E4E;
            padding-top:8px;
            text-align: center;
        }
    }

    &.form-home{
        .card-title{
            font-size: 24px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 0;
        }
    }
    &.card-form{
        .card-header{
        border-bottom:0px;
        padding:12px 0;
        }
        .card-body{
            padding:12px 0;  
        }
        .title-orange{
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            color: #ed8200;
        }

        .title-grey-light{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #9C9C9C;
            margin-bottom:6px;
        }

        .resume-plan{
            margin-bottom:24px;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #333333;

            &:after{
                content: "";
                margin-top:6px;
                height: 1px;
                width: 100%;
                display: block;
                background-color:#E8E8E8
            }
            .plan-resume{
                background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;   
            }

            .mount-resume{
                font-weight: 600;
                font-size: 24px;
                line-height: 24px;
            }
        }

        .title-form-purple{
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 30px;
            background: -webkit-linear-gradient(216.87deg, #3B62EC 13.99%, #9E3BEC 84.67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }


        .rccs{
            margin: 0;
            padding: 24px 0;
            .rccs__card--front{
                border-radius: 8px;
                border: solid 1px #e0e0e0;
                background: #f2f2f2!important;
                color:#000;
                box-shadow:none;
            }
            .rccs__chip{
                display: none;
            }
        }
        .big-title{
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #0a0a0b;
        }
        .text-end{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 160%;
            text-align: center;
            color: #0A0A0B;

            &.phone-end{
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 160%;  
                color: #9C9C9C;
                border: 1px solid #E0E0E0;
                box-sizing: border-box;
                border-radius: 4px;
                padding:8px;
                padding-left:32px;
                display: inline-block;
                position:relative;
                &:before{
                    position: absolute;
                    top: 9px;
                    left: 8px;
                    background-image: url('../../img/brand/question-icon.png');
                    background-repeat: no-repeat;
                    content: "";
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    margin-right: 4px;
                    
                }
            }
        }

        .text-eliminar{
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.14;
            letter-spacing: normal;
            color: #e40404;
            position: relative;
            float: right;
            cursor: pointer;
            &:before{
                background-image: url('../../img/icons/trash.svg');
                content: "";
                background-repeat: no-repeat;
                background-size: contain;
                width: 22px;
                height: 22px;
                position: absolute;
                left: -25px;
                top: 2px;
            }
            &:hover{
                color:#c20404;
                &:before{
                    background-image: url('../../img/icons/trash-hover.svg');
                }
            }
        }
    }
    &.card-select{
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(25, 24, 71, 0.08);
        border: solid 1px #e0e0e0;
        background-color: #ffffff;
        .custom-control{
            .custom-control-label{
                width: 100%;
            }
            .title{
                margin-top: -4px;
            }
            .row-card-select{
                margin-left: -46px;
            }
        } 
        .card-header{
            padding: 12px;
        }
        .card-body{
            padding: 12px;
        }
        &.selected{
            border-radius: 4px;
            box-shadow: 0 0 16px 0 rgba(43, 68, 255, 0.16);
            border: solid 4px #212a6e;
        }
        .title{
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            color: #0a0a0b;
            .price{
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.88;
                letter-spacing: normal;
                color: #ed8200;
              text-align: right;
            }
            .recomendado{
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.8;
                letter-spacing: normal;
                color: #ed8200;
                display: inline;
                border: 1px solid #ed8200;
                padding: 4px 8px;
                border-radius: 30px;
                margin-left: 8px;
            }
        }
        .sub-title{
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #8e8e9b;
            text-transform: uppercase;
        }
    }
}



.profile-page {
    .card-profile {
        margin-top: -150px;

        .card-profile-image {
            position: relative;
            //min-height: 130px;

            img {
                max-width: 180px;
                border-radius: $border-radius;
                @extend .shadow;
                transform: translate(-50%,-30%);
                position: absolute;
                left: 50%;
                transition: $transition-base;

                &:hover {
                    transform: translate(-50%, -33%);
                }
            }
        }

        .card-profile-stats {
            padding: 1rem 0;

            > div {
                text-align: center;
                margin-right: 1rem;
                padding: .875rem;

                &:last-child {
                    margin-right: 0;
                }

                .heading {
                    font-size: 1.1rem;
                    font-weight: bold;
                    display: block;
                }
                .description {
                    font-size: .875rem;
                    color: $gray-500;
                }
            }
        }

        .card-profile-actions {
            padding: .875rem;
        }

        @include media-breakpoint-down(xs) {
            .card-profile-actions {
                margin-top: 110px;
            } 
        }

        @include media-breakpoint-between(sm, md) {
            .card-profile-stats {
                margin-top: 30px;
            }
        }
    }
}

// Card with blockquotes

.card {
    .card-blockquote {
        padding: 2rem;
        position: relative;

        .svg-bg {
            display: block;
            width: 100%;
            height: 95px;
            position: absolute;
            top: -94px;
            left: 0;
        }
    }
}

// Animated cards

.card-lift--hover {
    &:hover {
        transform: translateY(-20px);
        @include transition($transition-base);
    }
}